$color-red: #ed0000;
$--color-text-secondary: RGBA(0, 0, 0, 50%);
$color-light-blue: #b7cbd3;
$color-light-grey: #ebeeef;
$color-white: white;
$color-green: green;
$color-orange: orange;
$color-blue-select-hover: #1967d2;

$color-blue: #285aff;
$color-blue-opacity-80: rgba(40, 90, 255, 0.8);
$color-blue-opacity-70: rgba(40, 90, 255, 0.7);
$color-blue-opacity-50: rgba(40, 90, 255, 0.5);
$color-blue-opacity-30: rgba(40, 90, 255, 0.3);
$color-blue-opacity-20: rgba(40, 90, 255, 0.2);

$color-vivid-tangerine: #FFAB91;
$color-vivid-tangerine-opacity-80: rgba(255, 171, 145, 0.8);
$color-vivid-tangerine-opacity-70: rgba(255, 171, 145, 0.7);
$color-vivid-tangerine-opacity-50: rgba(255, 171, 145, 0.5);
$color-vivid-tangerine-opacity-20: rgba(255, 171, 145, 0.2);

$color-light-green: #d0e287;
$color-light-green-opacity-80: rgba(208, 226, 135, 0.8);
$color-light-green-opacity-70: rgba(208, 226, 135, 0.7);
$color-light-green-opacity-50: rgba(208, 226, 135, 0.5);
$color-light-green-opacity-20: rgba(208, 226, 135, 0.2);

$color-pearl-aqua: #80CBC4;
$color-pearl-aqua-opacity-80: rgba(128, 203, 196, 0.8);
$color-pearl-aqua-opacity-70: rgba(128, 203, 196, 0.7);
$color-pearl-aqua-opacity-50: rgba(128, 203, 196, 0.5);
$color-pearl-aqua-opacity-20: rgba(128, 203, 196, 0.2);


$color-light-magenta: #CE93D8;
$color-light-magenta-opacity-80: rgba(206, 147, 216, 0.8);
$color-light-magenta-opacity-70: rgba(206, 147, 216, 0.7);
$color-light-magenta-opacity-50: rgba(206, 147, 216, 0.5);
$color-light-magenta-opacity-20: rgba(206, 147, 216, 0.2);

$color-topaz: #FFCC80;
$color-topaz-opacity-80: rgba(255, 204, 128, 0.8);
$color-topaz-opacity-70: rgba(255, 204, 128, 0.7);
$color-topaz-opacity-50: rgba(255, 204, 128, 0.5);
$color-topaz-opacity-20: rgba(255, 204, 128, 0.2);

$color-light-sky-blue: #90CAF9;
$color-light-sky-blue-opacity-80: rgba(144, 202, 249, 0.8);
$color-light-sky-blue-opacity-70: rgba(144, 202, 249, 0.7);
$color-light-sky-blue-opacity-50: rgba(144, 202, 249, 0.5);
$color-light-sky-blue-opacity-20: rgba(144, 202, 249, 0.2);

$color-wild-blue: #9FA8DA;
$color-wild-blue-opacity-80: rgba(159, 168, 218, 0.8);
$color-wild-blue-opacity-70: rgba(159, 168, 218, 0.7);
$color-wild-blue-opacity-50: rgba(159, 168, 218, 0.5);
$color-wild-blue-opacity-20: rgba(159, 168, 218, 0.2);