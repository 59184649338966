@import "node_modules/material-icons/iconfont/material-icons.css";
@import "node_modules/@totalenergiescode/design-system/dist/css/totalenergies-design-system.css";
@import "node_modules/@totalenergiescode/design-system/dist/css/custom-components/upload-files.css";
@import "node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~vis-timeline/styles/vis-timeline-graph2d.min.css";
@import "/src/app/roadmap/vis-timeline.scss";
@import "/src/app/eight-pillars/eight-pillars-mat-override.scss";
@import "/src/app/accordion/accordion.component.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "variables.scss";
body {
  font-family: Roboto, sans-serif;
  padding-bottom: 10px;
}

// Allow the toast to be displayed when a popup is opened
#toast-container {
  position: fixed;
  bottom: 12px;
  z-index: 7000;
}

.hidden-components {
  margin-bottom: 10px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 18px !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-width: unset !important;
  margin: 4px;
  border-radius: 8px;
}

.mat-calendar-body-in-range::before {
  background: #0000000d !important;
}

.mat-calendar-body-selected {
  background-color: #222b2d !important;
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #0000000d !important;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
  color: initial !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: initial !important;
}

.ng-dropdown-panel {
  padding: 10px;
  background-color: white !important;
  border: 1px solid lightgrey;
  border-radius: 8px;
  left: 0;
  top: 35px;
}

#manage-action-dialog {
  #comment {
    min-height: 80px;
    font {
      color: black !important;
    }
  }
}

caption {
  display: none;
}

#user-autocomplete {
  &.select-control-multiple {
    .ng-value {
      background-color: $color-light-grey;
      padding-right: 7px;
    }
    .ng-value-icon {
      border-right: none;
    }
  }
}

#add-group-dialog-container {
  .select-control-multiple {
    .ng-value {
      background-color: $color-light-grey;
      padding-right: 7px;
    }
    .ng-value-icon {
      border-right: none;
    }

    .ng-placeholder {
      padding-bottom: 0;
      top: 8px;
    }
  }
}

#product-details-container {
  .select-control-multiple {
    .ng-value {
      background-color: $color-light-grey;
      padding-right: 7px;
    }
    .ng-value-icon {
      border-right: none;
    }

    .ng-placeholder {
      padding-bottom: 0;
      top: 8px;
    }
  }
}
#add-product-dialog {
  .select-control-multiple {
    .ng-value {
      background-color: $color-light-grey;
      padding-right: 7px;
      display: flex;
      align-items: center;
    }
    .ng-value-icon {
      border-right: none;
    }

    .ng-placeholder {
      padding-bottom: 0;
      top: 8px;
    }
  }
}

.ng-select-disabled .ng-select-container {
  color: #8a8a8a;
  background-color: #edeff0 !important;
  border-color: #8a8a8a;
}

ng-select {
  .ng-arrow-wrapper::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-image: url("./assets/expand_more_black.svg");
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .ng-arrow-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.75rem !important;
  }

  .ng-clear-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ng-select-container {
    border-radius: 0.5rem !important;
    gap: 5px;
  }

  .ng-arrow-wrapper .ng-arrow {
    display: none !important;
  }

  .ng-option:hover {
    background-color: $color-blue-select-hover !important;
    color: white !important;
  }

  .ng-option-marked {
    background-color: $color-blue-select-hover !important;
    color: white !important;
  }

  .ng-option-selected {
    background-color: $color-blue-select-hover !important;
    color: white !important;
    .ng-option-label {
      font-weight: 100 !important;
    }
  }

  .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-radius: 0;
  }
}

.accordion-button:not(.collaped) {
  box-shadow: none !important;
}

.accordion-collapse::before {
  content: "";
  display: flex;
  border-top: 1px solid #b7cbd3;
  margin-left: 24px;
  margin-right: 24px;
}

.accordion-button {
  padding: 24px 24px !important;
}

.accordion-item .container-right {
  transform: none !important;
}

.accordion-header {
  align-items: center;
}

.form-select {
  background-image: url(./assets/expand_more_black.svg) !important;
  background-size: 24px 24px;
  background-position: right 0.45rem center !important;
}

.prosemirror-mention-node {
  color: blue !important;
}

.prosemirror-tag-node {
  color: red !important;
}

.suggestion-item-list {
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.suggestion-item {
  color: black;
  padding: 8px 10px;
  text-decoration: none;
  display: flex;
  gap: 5px;
}

.suggestion-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.suggestion-flag {
  width: 20px;
  height: 20px;
}

.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  content: "" !important;
}

.cdk-overlay-pane {
  max-height: 99vh;
}

.sortable-column {
  .column-header {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  cursor: pointer;
  align-items: center;

  .sort-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .expand-icon {
    font-size: 16px;
    color: gray;
    transition: color 0.3s ease;
  }

  .expand-icon.active {
    color: red;
  }
}
