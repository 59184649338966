@import "variables";

.color_red {
  color: $color-red;
}

.accordion {
  margin-bottom: 10px;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
}

.container-left-accordion-title {
  display: flex;
  gap: 20px;
  align-items: center;
}

.container-right {
  display: flex;
  align-items: center;
  gap: var(--Spacing-03, 8px);
  margin-left: auto;
  min-height: 37px;
}

.accordion-button::after {
  margin-left: var(--Spacing-03, 8px);
}

.show {
  background-color: #fff;
}

.accordion .accordion-item .accordion-header .container-right {
  position: absolute;
  right: 70px;
  transform: translateY(25%);
  z-index: 9;
}

.last-update-text {
  margin-right: 12px;
  color: #D9D9D9;
  font-size: 14px;
  font-weight: 400;
}