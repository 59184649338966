@import "variables";
.vis-timeline {
  border-radius: 2px;
}
.vis-timeline-container {
  .quarter {
    background-color: #222b2d;
    color: white;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #222b2d;
    font-size: 10px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    top: 2px !important;
    .vis-item-overflow .vis-item-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: inherit;

      p {
        margin-bottom: 0;
        margin-left: auto;
      }

      .flag {
        margin-left: auto;
      }
    }
  }

  .month {
    background-color: #ebeeef;
    color: black;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ebeeef;
    font-size: 14px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    top: 1px !important;
  }

  .group {
    background-color: $color-blue;
    color: white;
    border-radius: 4px;
    border: 1px solid $color-blue;
    font-size: 10px;
    height: 32px;
    font-weight: 700;
    top: 0px !important;
    cursor: pointer;

    .vis-item-overflow .vis-item-content {
      display: flex;
      align-items: center;
      height: inherit;
      max-width: calc(100vw - 100px);

      p {
        margin: 0;
      }

      .left-group-elements {
        display: flex;
        width: 100%;
        overflow: hidden;
      }

      .flag {
        position: absolute;
        border-radius: 14px;
      }

      .ready-for-site {
        background: #fb9a28;
      }

      .first-usage {
        color: black;
        background: white;
      }

      .go-live {
        background: #6fa136;
      }
    }
  }

  .event {
    color: #374649;
    text-align: center;
    border-radius: 4px;
    border-left: 4px solid $color-blue;
    font-size: 10px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0 !important;
    font-weight: 500;
    border-top: none;
    border-bottom: none;
    border-right: none;
    cursor: pointer;

    .vis-item-overflow .vis-item-content {
      display: flex;
      justify-content: space-between;
      height: inherit;
    }
  }

  .event.ready {
    background-color: $color-blue-opacity-70;
  }

  .event.on-going {
    background: repeating-linear-gradient(-45deg, rgba(147, 156, 202, 1) 0px, rgba(147, 156, 202, 1) 1px, transparent 2px, transparent 10px),
      $color-blue-opacity-50;
  }

  .event.on-going:hover {
    background: repeating-linear-gradient(-45deg, rgba(147, 156, 202, 1) 0px, rgba(147, 156, 202, 1) 1px, transparent 2px, transparent 10px),
      $color-blue-opacity-20;
  }

  .event.done {
    background-color: $color-blue-opacity-30;
  }

  .group:hover {
    background-color: $color-blue-opacity-70;
  }

  .event:hover {
    border-left: 4px solid $color-blue;
    background: $color-blue-opacity-20;
  }

  .highlighted-event {
    border-radius: 4px;
    border: 2px solid $color-blue-opacity-80;
    background-color: $color-blue-opacity-20;
  }

  .highlighted-group {
    border-radius: 4px;
    border: 2px solid $color-blue;
    background-color: $color-blue-opacity-70;
  }

  .group.technical {
    background-color: $color-vivid-tangerine;
    border: 1px solid $color-vivid-tangerine;
  }

  .group.technical:hover {
    background-color: $color-vivid-tangerine-opacity-70;
  }

  .event.technical {
    background-color: $color-vivid-tangerine-opacity-50;
    border-left: 4px solid $color-vivid-tangerine;
  }

  .event.technical:hover {
    background-color: $color-vivid-tangerine-opacity-20;
  }

  .highlighted-group.technical {
    border: 2px solid $color-vivid-tangerine;
    background-color: $color-vivid-tangerine-opacity-70;
  }

  .highlighted-event.technical {
    border: 2px solid $color-vivid-tangerine;
    background-color: $color-vivid-tangerine-opacity-20;
  }

  .group.deploy {
    background-color: $color-light-magenta;
    border: 1px solid $color-light-magenta;
  }

  .group.deploy:hover {
    background-color: $color-light-magenta-opacity-70;
  }

  .event.deploy {
    background-color: $color-light-magenta-opacity-50;
    border-left: 4px solid $color-light-magenta;
  }

  .event.deploy:hover {
    background-color: $color-light-magenta-opacity-20;
  }

  .highlighted-group.deploy {
    border: 2px solid $color-light-magenta;
    background-color: $color-light-magenta-opacity-70;
  }

  .highlighted-event.deploy {
    border: 2px solid $color-light-magenta;
    background-color: $color-light-magenta-opacity-20;
  }

  .group.run {
    background-color: $color-pearl-aqua;
    border: 1px solid $color-pearl-aqua;
  }

  .group.run:hover {
    background-color: $color-pearl-aqua-opacity-70;
  }

  .event.run {
    background-color: $color-pearl-aqua-opacity-50;
    border-left: 4px solid $color-pearl-aqua;
  }

  .event.run:hover {
    background-color: $color-pearl-aqua-opacity-20;
  }

  .highlighted-group.run {
    border: 2px solid $color-pearl-aqua;
    background-color: $color-pearl-aqua-opacity-70;
  }

  .highlighted-event.run {
    border: 2px solid $color-pearl-aqua;
    background-color: $color-pearl-aqua-opacity-20;
  }

  .group.cyber {
    background-color: $color-topaz;
    border: 1px solid $color-topaz;
  }

  .group.cyber:hover {
    background-color: $color-topaz-opacity-70;
  }

  .event.cyber {
    background-color: $color-topaz-opacity-50;
    border-left: 4px solid $color-topaz;
  }

  .event.cyber:hover {
    background-color: $color-topaz-opacity-20;
  }

  .highlighted-group.cyber {
    border: 2px solid $color-topaz;
    background-color: $color-topaz-opacity-70;
  }

  .highlighted-event.cyber {
    border: 2px solid $color-topaz;
    background-color: $color-topaz-opacity-20;
  }

  .group.func_adapt {
    background-color: $color-light-sky-blue;
    border: 1px solid $color-light-sky-blue;
  }

  .group.func_adapt:hover {
    background-color: $color-light-sky-blue-opacity-70;
  }

  .event.func_adapt {
    background-color: $color-light-sky-blue-opacity-50;
    border-left: 4px solid $color-light-sky-blue;
  }

  .event.func_adapt:hover {
    background-color: $color-light-sky-blue-opacity-20;
  }

  .highlighted-group.func_adapt {
    border: 2px solid $color-light-sky-blue;
    background-color: $color-light-sky-blue-opacity-70;
  }

  .highlighted-event.func_adapt {
    border: 2px solid $color-light-sky-blue;
    background-color: $color-light-sky-blue-opacity-20;
  }

  .group.func_evo {
    background-color: $color-wild-blue;
    border: 1px solid $color-wild-blue;
  }

  .group.func_evo:hover {
    background-color: $color-wild-blue-opacity-70;
  }

  .event.func_evo {
    background-color: $color-wild-blue-opacity-50;
    border-left: 4px solid $color-wild-blue;
  }

  .event.func_evo:hover {
    background-color: $color-wild-blue-opacity-20;
  }

  .highlighted-group.func_evo {
    border: 2px solid $color-wild-blue;
    background-color: $color-wild-blue-opacity-70;
  }

  .highlighted-event.func_evo {
    border: 2px solid $color-wild-blue;
    background-color: $color-wild-blue-opacity-20;
  }

  .vis-foreground .vis-group {
    border-bottom: none;
  }

  .vis-foreground .vis-group.all-hidden {
    height: 0 !important;
  }

  .vis-current-time {
    background-color: #222b2d;
    width: 2px;
  }

  .hidden {
    visibility: hidden;
    display: none;
  }

  .expand-less .up-arrow {
    transform: rotate(180deg);
  }
}
